import React from "react";
import "./Vision.css";
import Circuit from "../assets/Circuit2.PNG";
import Blocks from "../assets/blocks5.png";
import collaboration from "../assets/collaboration.svg";
import profit from "../assets/profit.svg";
import knowledge from "../assets/knowledge.svg";
import tie from "../assets/tie.svg";
import handshake from "../assets/handshake.svg";
import Nav from "./Nav";
import IconGif from '../assets/IconGif.gif'
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState, useRef } from "react";

const Vision = () => {
  const [loaded, setLoaded] = useState(false);
  const collapseRefs = useRef([]);


  const handleIconHover = (index) => {
    //console.log(collapseRefs.current, index, collapseRefs.current[index])
    // Check if the ref exists before trying to scroll into view

    window.scrollTo({
      top: collapseRefs.current[index].offsetTop,
      behavior: 'smooth'
  })
  
  
  };



  

  const iconInfo = [
    {
      title: "Professionalism & Expertise",
      description:
        "Being a member of Codex is significant. As such, it is expected that the DAO is appropriately represented outside its own platforms. Moreover, Codex places value on professional marketing practices and does not engage in engagement farming tactics that may be intrusive to other users. In Codex, we value and take into consideration the opinions of people who have expertise in specific areas.",
      image: tie,
    },
    {
      title: "Transparency & Trust",
      description:
        "Codex operates on a governance system whereby all decisions are determined by The Council through a vote. DAO funds are controlled through a multi-sig wallet. All funds generated from Codex, such as those from auctions, are deposited into the DAO wallet, and no individual benefits personally from the earnings. Additionally, we promote a culture of transparency and accountability within the group to build trust and foster long-term relationships. Members should be honest and transparent about their activities, interests and intentions.",
      image: knowledge,
    },
    {
      title: "Community & Cooperation",
      description:
        "Members are encouraged to collaborate and share knowledge, and the group as a whole aims to foster a highly informative environment that benefits all members. To achieve this, all members are expected to contribute according to their individual strengths and areas of expertise. Ultimately, the goal is for Codex to be seen as a welcoming and inclusive Web3 home for all members.",
      image: collaboration,
    },
    {
      title: "Alpha & Profitability",
      description:
        "Codex fosters a collaborative approach to alpha hunting, with the aim of redefining the traditional “hunt and serve” structure typically seen in other NFT groups. Alpha hunting encompasses a wide range of activities, and no member is expected to excel in all aspects. Whether it’s whitelist hunting, being early, or other forms of alpha hunting.  Any contribution in these areas is valued and sufficient to foster an alpha environment where every member is able to  achieve high levels of profitability.",
      image: profit,
    },
    {
      title: "Collaboration & Partnership",
      description:
        "As an ambitious DAO, we prioritize collaboration with game-changing projects and their leaders, and seek to invest in these projects and support their ecosystems. Our approach to partnerships is grounded in a commitment to being a serious and reliable partner. In line with our aspiration to constantly learn and grow, we also look forward to collaborating with other Web3 groups, either by sharing knowledge, insights, and best practices, or by learning from them.",
      image: handshake,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);



  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage === 100) {
          clearInterval(interval);
        }

        return prevPercentage + 1;
      });
    }, 10);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const doThing = () => {
      const images = document.getElementsByTagName("img")
      const imageArr = [...images];
      const numImages = imageArr.length;
      document.body.style.backgroundColor = "transparent";

      let numLoaded = 0;
      const onLoad = () => {
        numLoaded++;
        if (numLoaded === numImages) {
          setLoaded(true);
        }
      };

      for (let i = 0; i < numImages; i++) {
        const image = imageArr[i];
        if (image.complete) {
          onLoad();
        } else {
          image.addEventListener("load", onLoad);
        }
      }
    };
    setTimeout(() => {
      // document.addEventListener("DOMContentLoaded", doThing());
      doThing();
    }, 1100);
    // doThing();

    // return () => {
    //   for (let i = 0; i < numImages; i++) {
    //     const image = images[i];
    //     //image.removeEventListener("load", onLoad);
    //   }
    // };
  }, []);



  

  


  return (

   <div className="h-[100%] w-[100%]">
<div className={`loading-container bg-black h-[100%] w-[100%]   ${loaded ? 'hidden' : 'flex'}`}>
          <img src={IconGif} alt="Loading gif" className="gif" />
          <div
            className="loading-bar"
            style={{ width: `${loadingPercentage}%` }}
          ></div>
        </div>

        <div
        className={` md:px-4 items-center justify-start vision-container transition-opacity duration-1000 h-[100%]` } style={{ backgroundImage: `url(${Circuit})` }}
      >
    <Nav currentpage={"vision"}/>
            <div className='container  mx-auto mt-16 flex lg:flex-row flex-col  md:gap-16  gap-8 '>
          <div className='vision-con-one sm:flex-col md:flex lg:flex-col lg:min-w-[40%] gap-8 lg:gap-0  '>
            <div className='sm:container flex flex-col grow text-white gap-4 items-start'>
              <h1 className='heading first-h '>The 5 Pillars of Our Approach</h1>
              <p className='regular-text'>
                Codex seeks to maintain a professional, diverse, and highly
                inclusive community engaged to serve as a driving force in Web3.
                The primary goal of Codex is to create an optimal environment that
                enables our members to thrive and achieve their goals. We believe
                in working together towards success and we strongly encourage our
                members to align themselves with this vision.
              </p>
              <p className='regular-text'>
                We have developed a thoughtful strategy to achieve our goals. In
                summary, this strategy is based on the pillars outlined to the
                right.
              </p>
              <div className='container flex mt-8 md:mt-0  lg:mt-8 mb-8'>
                <div className='container mx-auto flex  block-image  sm:justify-center lg:justify-start w-[100%]'>
                  <img
                    src={Blocks}
                    alt=''
                    className='object-cover blocks lg:w-[70%]'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='vision-con-two container flex flex-col justify-center gap-8 lg:min-w-[50%] mb-8'>
            {iconInfo.map((icon, index) => (
          <div
          key={index}
          className="container mx-auto flex collapse-container"
          ref={(el) => (collapseRefs.current[index] = el)}
        >                <div className='collapse' onClick={() => handleIconHover(index)}>
                  <input type='checkbox' />
                  <div className='collapse-title text-xl font-medium flex  justify-between'>
                    <div className='flex items-center justify-between'>
                      <div className='flex items-center gap-4'>
                        <img src={icon.image} className='icon' alt='' />
                        <div className=' collapse-heading text-white '>
                          {icon.title}
                        </div>
                      </div>
                    </div>
                    <div className=' items-center flex  grow justify-end '>
                      <AiOutlinePlus className='text-white small-heading aioutlineplus' />
                    </div>
                  </div>
                  <div className='collapse-content'>
                    <p className='regular-text text-white mt-4'>
                      {icon.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


   </div>







  );
};

export default Vision;






