import React from "react";
import "./GalleryCard.css";

const GalleryCard = (props) => {
  const { link, name, bio, onClick } = props;
  return (
    <div
      className='container gallery-card-container object-contain nft-container'
      data-index={props.index}
    >
      <img
        src={props.link}
        alt=''
        className='gallery-card-container object-contain testtt'
        data-index={props.index}
        onClick={onClick}
      />
      <div className='gallery-card-overlay object-contain'>{name}</div>
    </div>
  );
};

export default GalleryCard;
