import React from "react";
import "./Gallery.css";
import one from "../assets/1.png";
import two from "../assets/2.png";
import three from "../assets/3.png";
import four from "../assets/4.png";
import five from "../assets/5.png";
import six from "../assets/6.png";
import seven from "../assets/7.png";
import eight from "../assets/8.png";
import nine from "../assets/9.png";
import ten from "../assets/10.png";
import eleven from "../assets/11.png";
import twelve from "../assets/12.png";
import thirteen from "../assets/13.png";
import fourteen from "../assets/14.png";
import fifteen from "../assets/uncloaked.png";
import sixteen from "../assets/16.png";
import bunnyMan from "../assets/bunnyman.png";
import gb from "../assets/gb.png";
import miker from "../assets/miker.png";
import binnie from "../assets/binnie.png";
import groov from "../assets/groovi.png";
import mich from "../assets/mich.png";
import coolguy from "../assets/coolguy.png";
import bengi from "../assets/bengi.png";
import rii from "../assets/rii.png";
import GalleryCard from "./GalleryCard";
import BlackCircuits from "../assets/BlackCircuits.PNG";
import TwitterIcon from "../assets/twitter.svg";
import IconGif from "../assets/IconGif.gif";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { useState, useEffect } from "react";

const Gallery = (props) => {
  const [people, setPeople] = useState(props.members);
  const [currentImage, setCurrentImage] = useState(0);
  const [selectedImage, setSelectedImage] = useState(ten);
  const [selectedImageNft, setSelectedImageNft] = useState("The RamBorg");
  const [selectedImageBio, setSelectedImageBio] = useState(
    "An aspiring engineer, my journey with Web3 began with play-to-earn games such as Zed Run and Axie Infinity. While I occasionally traded Polygon and Ethereum NFTs, I became completely hooked by the Solana NFT scene. As an alpha hunter, I have had the opportunity to forge valuable connections in this space, as well as simply meet incredible people every day."
  );
  const [selectedImageName, setSelectedImageName] = useState("Jgonkas");
  const [selectedImageTwitter, setSelectedImageTwitter] = useState(
    "https://twitter.com/jgonkas"
  );
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const navigate = useNavigate();

  const handleApplyNowClick = () => {
    navigate("/application");
  };

  const handleContainerClick = () => {
    if (images[currentImage].name === "Apply Now") {
      handleApplyNowClick();
    }
    // Add any other logic or actions for the container click here
    
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage === 100) {
          clearInterval(interval);
        }

        return prevPercentage + 1;
      });
    }, 10);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const doThing = () => {
      const images = document.getElementsByClassName("testtt");
      const imageArr = [...images];
      const numImages = imageArr.length;
      document.body.style.backgroundColor = "transparent";

      let numLoaded = 0;
      const onLoad = () => {
        numLoaded++;
        if (numLoaded === numImages) {
          setLoaded(true);
        }
      };

      for (let i = 0; i < numImages; i++) {
        const image = imageArr[i];
        if (image.complete) {
          onLoad();
        } else {
          image.addEventListener("load", onLoad);
        }
      }
    };
    setTimeout(() => {
      // document.addEventListener("DOMContentLoaded", doThing());
      doThing();
    }, 1100);
    // doThing();

    // return () => {
    //   for (let i = 0; i < numImages; i++) {
    //     const image = images[i];
    //     //image.removeEventListener("load", onLoad);
    //   }
    // };
  }, []);





  const images = [
    {
      name: "Jgonkas",
      bio: "An aspiring engineer, my journey with Web3 began with play-to-earn games such as Zed Run and Axie Infinity. While I occasionally traded Polygon and Ethereum NFTs, I became completely hooked by the Solana NFT scene. As an alpha hunter, I have had the opportunity to forge valuable connections in this space, as well as simply meet incredible people every day.",
      src: ten,
      twitter: "https://twitter.com/jgonkas",
      nft: "The Ramborg",
    },
    {
      name: ".Tim",
      bio: "Co-founder of Mushroom Racers, an innovative and thriving project that has delivered ongoing value since March of 2022. An NFT enthusiast in Web3 and an aspiring sports journalist in Web2. Enjoys engaging with communities, lively debates and a hint of sarcasm.",
      src: five,
      twitter: "https://twitter.com/K1896T",
      nft: "The Bot",
    },
    {
      name: "bodi!",
      bio: "Technology nerd since just a kid, turned professional IT consultant (worked for several FTSE 100/S&P 500 companies), turned web3 passionate. Mushroom Racers Solana NFT co-founder. Father of two. Benfica fan. Trying to make Solana and web3 a better place!",
      src: six,
      twitter: "https://twitter.com/cryptodizl",
      nft: "The Heisenbug",
    },
    {
      name: "BlueFungus",
      bio: "Entered web3 in the 2021 bullrun as a miner. Got swallowed up by SOL nfts and never looked back. Current web3 focus is alpha hunting and the occasional art direction. Dad of 2 boys, professional background in high end VFX.",
      src: eight,
      twitter: "https://twitter.com/BlueFungible",
      nft: "The Locksmith",
    },
    {
      name: "Akka",
      bio: "Professional background in film and television. Joined Web3 in 2021 and rose through the ranks of The Kozmos, helping to shape its community. Currently serving as the Community Manager for Bōku Labz.",
      src: fourteen,
      twitter: "https://twitter.com/shinakka",
      nft: "The Servant",
    },
    {
      name: "Baffled Scientist",
      bio: "Started in 2021 when I noticed that something crazy was going on with the magical internet money that I had heard about years ago, and have been completely enthralled by the collision of technological wizardry, artistic expression, cultural innovation, and degenerate gambling that I have observed. Still wandering through this world primarily in a state of confusion but gradually gaining enough experience to hopefully help guide others while exploring the next destination. There's always another Tungsten Cube just around the corner...",
      src: three,
      twitter: "https://twitter.com/tokamak919",
      nft: "The Prismatic",
    },
    {
      name: "Techbag",
      bio: "Background in BizOps, Finance, and Data Analytics. Joined crypto in 2017, but didn’t get fully immersed until 2021. Average trader and excellent holdooor. After getting my feet wet in Solana NFTs with the Galactic Geckos mint, I eventually went on to join the Geckos team, which I currently advise on tech and strategy. I was also an early contributor to Kozmos.",
      src: seven,
      twitter: "https://twitter.com/tech_bag_",
      nft: "The Starfarer",
    },
    {
      name: "Lionclaws",
      bio: "TBA.",
      src: nine,
      twitter: "https://twitter.com/Lionclaws_",
      nft: "The Inevitable",
    },
    {
      name: "Coquito",
      bio: "I'm a mediocre trader turned NFT degen. I've been hooked on computers since Windows 3.0, so much that I majored in Computer Sciences only to find out I enjoy teaching way more than coding. English/Español.",
      src: eleven,
      twitter: "https://twitter.com/AlphaCoquito",
      nft: "The Beach Bum",
    },
    {
      name: "Akshit",
      bio: "Crypto Degen Since 2021. Rode the metaverse wave at first, been in NFTs ever since September 2021. Have been a community manager and marketing strategist for some big name projects. If you use Matic, you are a bozo and not even Sandeep can save you.",
      src: one,
      twitter: "https://twitter.com/TraderAkshit",
      nft: "The Robo Drag",
    },
    {
      name: "MascotRay",
      bio: "\"Just be kind.\"",
      src: thirteen,
      twitter: "https://twitter.com/mascotray",
      nft: "The Fortunate",
    },
    {
      name: "John Snow",
      bio: "Previously known as Lord Commander of the Night’s Watch, King Beyond the Wall, the Undead, the Queenslayer, The Bastard of Winterfell, the Last Dragon and the rightful ruler of the seven kingdoms. After all that fell apart i became a Researcher and a University Professor, currently im in the final stages of my third degree, a PhD in neuroscience. Started my crypto journey early 2021, first it was crypto trading, moved to nfts after minting Geckos, ever since i am hooked. Kozmos and Mushroom Racers alpha hunter.",
      src: four,
      twitter: "https://twitter.com/NeuroSteven",
      nft: "The Bastard",
    },
    {
      name: "Rai",
      bio: "I have been crypto investing and trading since 2017 and trading NFTs since 2020, coming from web2 with experience in risk management and data analysis. The part I enjoy the most about NFTs is finding and investing in promising projects early as this is where the most risk and reward is. I am usually taking a logical approach when it comes to entering and exiting any investments to avoid overthinking and mistakes. Co-founder for Mushroom Racers and founder of Rai DAO.",
      src: sixteen,
      twitter: "https://twitter.com/CryptoTraderRai",
      nft: "The Sniper",
    },
    {
      name: "Imteaz",
      bio: "I'm a doctor turned NFT enthusiast, driven by a newfound passion for the limitless possibilities of NFTs. Balancing my medical career with fervent exploration of blockchain technology, I strive to unravel the intricate wonders of this captivating realm and its groundbreaking innovations. Embracing both healing and digital currencies, I embark on an extraordinary journey of knowledge and impact.",
      src: two,
      twitter: "https://twitter.com/imteaz1997",
      nft: "The Antiquist",
    },
    {
      name: "BunnyMan",
      bio: "Bored of my corporate life, I decided to hop into the World of Degens in 2021 with the aim to explore & experience the NFT space and totally fell in love with it. A firm believer of \"Buy High, Sell Low\", I hold NFT's either to see them moon or to let them taste the dust. I am a polite shit talker who pretends to be good at alpha hunting and collab management. Oh, I have three bunnies! They call me Mad Hopper.",
      src: bunnyMan,
      twitter: "https://twitter.com/SabkaWick",
      nft: "The Mad Hopper",
    },
    {
      name: "gb",
      bio: "",
      src: gb,
      twitter: "https://twitter.com/0xgabi",
      nft: "The Tatsu",
    },
    {
      name: "binnie",
      bio: "",
      src: binnie,
      twitter: "https://twitter.com/alienstolemytea",
      nft: "The Artist",
    },
    {
      name: "groovinpict",
      bio: "",
      src: groov,
      twitter: "https://twitter.com/groovin_pict",
      nft: "The Wanderer",
    },
    {
      name: "michwitt",
      bio: "I am Mich, a dedicated builder in the Web3 space since 2021. My journey began as an Alpha Hunter for a big project during their prime, where I played a pivotal role in shaping their community. Now, I am focused on creating the foundation for innovation in both the alpha and art sectors through my own 1/1 project Perceiver.",
      src: mich,
      twitter: "https://twitter.com/michwitt_",
      nft: "The Eye",
    },
    {
      name: "Miker",
      bio: "",
      src: miker,
      twitter: "https://twitter.com/BigMikeCrypto ",
      nft: "The Bone",
    },
    {
      name: "ItsThatCoolGuy",
      bio: "ItsThatCoolGuy joined DeFi in 2020 and the Solana ecosystem in 2021. He is well known in the space as a contributor to many DAO's serving as a provider of market analysis and economic insights. He also is on many DAOs' teams serving in various capacities from copywriter to advisor.",
      src: coolguy,
      twitter: "https://twitter.com/ItsThatCoolSoul",
      nft: "The Zen",
    },
    {
      name: "bengi",
      bio: "",
      src: bengi,
      twitter: "https://twitter.com/bengi_sol",
      nft: "The Flipper",
    },
    {
      name: "Riisaurus",
      bio: "There were mistakes made in a wallet that I controlled",
      src: rii,
      twitter: "https://twitter.com/Riisaurus_",
      nft: "The Saurian",
    },
    {
      name: "Apply Now",
      bio: "Who will be next?...",
      src: fifteen,
      twitter: "https://twitter.com/CodexDao",
      nft: "The ?",
    },
  ];

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const imageSelect = (e) => {
    console.log(e.target, 1);
  };

  const handleCardHover = (e, link, name, bio, nft, twitter) => {
    setSelectedImage(link);
    setSelectedImageName(name);
    setSelectedImageBio(bio);
    setSelectedImageNft(nft);
    setSelectedImageTwitter(twitter);
  };

  return (
    <div className='h-[100%] w-[100%]'>
<div className={`loading-container bg-black h-[100%] w-[100%]   ${loaded ? 'hidden' : 'flex'}`}>
          <img src={IconGif} alt='Loading gif' className='gif' />
          <div
            className='loading-bar'
            style={{ width: `${loadingPercentage}%` }}
          ></div>
        </div>
        <div
          className={` md:px-4 items-center justify-start vision-container  h-[100%]`}
          style={{ backgroundImage: `url(${BlackCircuits})` }}
        >
          <Nav currentpage={"gallery"} />

          <div className={`container flex lg:flex-row flex-col  body-container  opacity-0  duration-250  mb-8 mt-16 p-8 gallery-full-container gap-8 ${
            loaded ? "opacity-100" : "opacity-0"
          }`}>
            <div className='upper-one '>
              <div className='upper-image-container flex flex-col items-center'>
                <div className='image-border'>
                  <img
                    src={selectedImage}
                    alt=''
                    className='selected-image testtt'
                  />
                </div>
                <div className='title text-white'>
                  {selectedImageName}{" "}
                  <a
                    href={selectedImageTwitter}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='twitter-icon'
                  >
                    <img
                      src={TwitterIcon}
                      alt='Twitter Link'
                      className='link'
                    />
                  </a>
                </div>
                <div className='alt-tex text-white'>{selectedImageNft}</div>
              </div>
              <div className='bio-text p-4 text-white regular-text'>
                <div className='overflow-scroll'>{selectedImageBio}</div>
              </div>
            </div>
            <div className='upper-two '>
              <div className='gallery-row flex '>
                <GalleryCard
                  link={ten}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      ten,
                      "Jgonkas",
                      "Aspiring to be an engineer. Joined Web3 in early 2021, delved into play-to-earn games like Zed Run, Axie Infinity, and others, while occasionally traded Polygon and Eth NFTs. In early 2022, I discovered Solana NFTs and became completely hooked. Working as an alpha hunter, I have had the opportunity to forge valuable connections and meet incredible individuals. I pride myself on being a motivated person who constantly seeks out new technologies and opportunities, both in terms of innovation and financial possibilities.",
                      "The RamBorg",
                      "https://twitter.com/jgonkas"
                    )
                  }
                  name='Jgonkas'
                />
                <GalleryCard
                  link={five}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      five,
                      ".Tim",
                      "Co-founder of Mushroom Racers, an innovative and thriving project that has delivered ongoing value since March of 2022. An NFT enthusiast in Web3 and an aspiring sports journalist in Web2. Enjoys engaging with communities, lively debates and a hint of sarcasm.",
                      "The Bot",
                      "https://twitter.com/K1896T"
                    )
                  }
                  name='.Tim'
                />
                <GalleryCard
                  link={six}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      six,
                      "bodi!",
                      "Technology nerd since just a kid, turned professional IT consultant (worked for several FTSE 100/S&P 500 companies), turned web3 passionate. Mushroom Racers Solana NFT co-founder. Father of two. Benfica fan. Trying to make Solana and web3 a better place!",
                      "The Heisenbug",
                      "https://twitter.com/cryptodizl"
                    )
                  }
                  name='bodi!'
                />
                <GalleryCard
                  link={eight}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      eight,
                      "BlueFungus",
                      "Entered web3 in the 2021 bullrun as a miner. Got swallowed up by SOL nfts and never looked back. Current web3 focus is alpha hunting and the occasional art direction. Dad of 2 boys, professional background in high end VFX.",
                      "The Locksmith",
                      "https://twitter.com/BlueFungible"
                    )
                  }
                  name='BlueFungus'
                />
                <GalleryCard
                  link={fourteen}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      fourteen,
                      "Akka",
                      "Professional background in film and television. Joined Web3 in 2021 and rose through the ranks of The Kozmos, helping to shape its community. Currently serving as the Community Manager for Bōku Labz.",
                      "The Servant",
                      "https://twitter.com/shinakka"
                    )
                  }
                  name='Akka'
                />
              </div>
              <div className='gallery-row '>
                <GalleryCard
                  link={three}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      three,
                      "Baffled Scientist",
                      "Started in 2021 when I noticed that something crazy was going on with the magical internet money that I had heard about years ago, and have been completely enthralled by the collision of technological wizardry, artistic expression, cultural innovation, and degenerate gambling that I have observed. Still wandering through this world primarily in a state of confusion but gradually gaining enough experience to hopefully help guide others while exploring the next destination. There's always another Tungsten Cube just around the corner...",
                      "The Prismatic",
                      "https://twitter.com/tokamak919"
                    )
                  }
                  name='Baffled Scientist'
                />
                <GalleryCard
                  link={seven}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      seven,
                      "TechBag",
                      "Background in BizOps, Finance, and Data Analytics. Joined crypto in 2017, but didn’t get fully immersed until 2021. Average trader and excellent holdooor. After getting my feet wet in Solana NFTs with the Galactic Geckos mint, I eventually went on to join the Geckos team, which I currently advise on tech and strategy. I was also an early contributor to Kozmos.",
                      "The Starfarer",
                      "https://twitter.com/tech_bag_"
                    )
                  }
                  name='TechBag'
                />
                <GalleryCard
                  link={nine}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      nine,
                      "Lionclaws",
                      "TBA.",
                      "The Inevitable",
                      "https://twitter.com/Lionclaws_"
                    )
                  }
                  name='Lionclaws'
                />
                <GalleryCard
                  link={two}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      two,
                      "Imteaz",
                      "I'm a doctor turned NFT enthusiast, driven by a newfound passion for the limitless possibilities of NFTs. Balancing my medical career with fervent exploration of blockchain technology, I strive to unravel the intricate wonders of this captivating realm and its groundbreaking innovations. Embracing both healing and digital currencies, I embark on an extraordinary journey of knowledge and impact.",
                      "The Antiquist",
                      "https://twitter.com/imteaz1997"
                    )
                  }
                  name='Imteaz'
                />
                <GalleryCard
                  link={eleven}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      eleven,
                      "Coquito",
                      "I'm a mediocre trader turned NFT degen. I've been hooked on computers since Windows 3.0, so much that I majored in Computer Sciences only to find out I enjoy teaching way more than coding. English/Español.",
                      "The Beach Bum",
                      "https://twitter.com/AlphaCoquito"
                    )
                  }
                  name='Coquito'
                />
              </div>
              <div className='gallery-row '>
                <GalleryCard
                  link={one}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      one,
                      "Akshit",
                      "Crypto Degen Since 2021. Rode the metaverse wave at first, been in NFTs ever since September 2021. Have been a community manager and marketing strategist for some big name projects. If you use Matic, you are a bozo and not even Sandeep can save you.",
                      "The Robo Drago",
                      "https://twitter.com/TraderAkshit"
                    )
                  }
                  name='Akshit'
                />
                <GalleryCard
                  link={thirteen}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      thirteen,
                      "MascotRay",
                      "\"Just be kind.\"",
                      "The Fortunate",
                      "https://twitter.com/mascotray"
                    )
                  }
                  name='MascotRay'
                />
                <GalleryCard
                  link={four}
                  onClick={(e) =>
                    handleCardHover(
                      e,
                      four,
                      "John Snow",
                      "Previously known as Lord Commander of the Night’s Watch, King Beyond the Wall, the Undead, the Queenslayer, The Bastard of Winterfell, the Last Dragon and the rightful ruler of the seven kingdoms. After all that fell apart i became a Researcher and a University Professor, currently im in the final stages of my third degree, a PhD in neuroscience. Started my crypto journey early 2021, first it was crypto trading, moved to nfts after minting Geckos, ever since i am hooked. Kozmos and Mushroom Racers alpha hunter.",
                      "The Bastard",
                      "https://twitter.com/NeuroSteven"
                    )
                  }
                  name='John Snow'
                />
                <GalleryCard
                  link={sixteen}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      sixteen,
                      "Rai",
                      "I have been crypto investing and trading since 2017 and trading NFTs since 2020, coming from web2 with experience in risk management and data analysis. The part I enjoy the most about NFTs is finding and investing in promising projects early as this is where the most risk and reward is. I am usually taking a logical approach when it comes to entering and exiting any investments to avoid overthinking and mistakes. Co-founder for Mushroom Racers and founder of Rai DAO.",
                      "The Sniper",
                      "https://twitter.com/CryptoTraderRai"
                    );
                  }}
                  name='Rai'
                />
                <GalleryCard
                  link={bunnyMan}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      bunnyMan,
                      "BunnyMan",
                      "Bored of my corporate life, I decided to hop into the World of Degens in 2021 with the aim to explore & experience the NFT space and totally fell in love with it. A firm believer of \"Buy High, Sell Low\", I hold NFT's either to see them moon or to let them taste the dust. I am a polite shit talker who pretends to be good at alpha hunting and collab management. Oh, I have three bunnies! They call me Mad Hopper.",
                      "The Mad Hopper",
                      "https://twitter.com/SabkaWick"
                    );
                  }}
                  name='BunnyMan'
                />
              </div>
              <div className='gallery-row '>
              <GalleryCard
                  link={gb}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      gb,
                      "gb",
                      "",
                      "The Tatsu",
                      "https://twitter.com/0xgabi"
                    );
                  }}
                  name='gb'
                />

                <GalleryCard
                  link={binnie}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      binnie,
                      "binnie",
                      "",
                      "The Artist",
                      "https://twitter.com/alienstolemytea"
                    );
                  }}
                  name='binnie'
                />

              <GalleryCard
                  link={groov}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      groov,
                      "groovinpict",
                      "",
                      "The Wanderer",
                      "https://twitter.com/groovin_pict"
                    );
                  }}
                  name='groovinpict'
                />
              <GalleryCard
                  link={mich}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      mich,
                      "michwitt",
                      "I am Mich, a dedicated builder in the Web3 space since 2021. My journey began as an Alpha Hunter for a big project during their prime, where I played a pivotal role in shaping their community. Now, I am focused on creating the foundation for innovation in both the alpha and art sectors through my own 1/1 project Perceiver.",
                      "The Eye",
                      "https://twitter.com/michwitt_"
                    );
                  }}
                  name='michwitt'
                />

                <GalleryCard
                  link={miker}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      miker,
                      "Miker",
                      "",
                      "The Bone",
                      "https://twitter.com/BigMikeCrypto"
                    );
                  }}
                  name='Miker'
                />
              </div>
              <div className='gallery-row '>
              <GalleryCard
                  link={coolguy}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      coolguy,
                      "ItsThatCoolGuy",
                      "ItsThatCoolGuy joined DeFi in 2020 and the Solana ecosystem in 2021. He is well known in the space as a contributor to many DAO's serving as a provider of market analysis and economic insights. He also is on many DAOs' teams serving in various capacities from copywriter to advisor.",
                      "The Zen",
                      "https://twitter.com/ItsThatCoolSoul"
                    );
                  }}
                  name='ItsThatCoolGuy'
                />
              <GalleryCard
                  link={bengi}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      bengi,
                      "bengi",
                      "",
                      "The Flipper",
                      "https://twitter.com/bengi_sol"
                    );
                  }}
                  name='bengi'
                />

              <GalleryCard
                  link={rii}
                  onClick={(e) => {
                    handleCardHover(
                      e,
                      rii,
                      "Riisaurus",
                      "There were mistakes made in a wallet that I controlled",
                      "The Saurian",
                      "https://twitter.com/Riisaurus_"
                    );
                  }}
                  name='Riisaurus'
                />
              <GalleryCard
                  link={fifteen}
                  onClick={handleApplyNowClick}
                  name='Apply Now'
                />
              </div>
            </div>
          </div>
          <div className='container mx-auto' onClick={handleContainerClick}>
            <div className='md:hidden mt-16'>
              <img
                src={images[currentImage].src}
                alt='carousel image relative '
                className='mobile-gallery-img'
              />
              <div className='carousel-buttons mb-8'>
                <button onClick={prevImage}>
                  <BsFillArrowLeftCircleFill />
                </button>
                <button onClick={nextImage}>
                  <BsFillArrowRightCircleFill />
                </button>
              </div>
              <div className='sm:heading heading-small'>
                {images[currentImage].name}
                <a
                  href={images[currentImage].twitter}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='twitter-icon ml-8'
                >
                  <img src={TwitterIcon} alt='Twitter Link' className='link' />
                </a>
              </div>
              <div className='regular-text text-white'>
                {images[currentImage].nft}
              </div>
              <div className='text-white mt-4 mb-4'>
                {images[currentImage].bio}
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Gallery;
