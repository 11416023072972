import React from "react";
import "./Application.css";
import { useState, useEffect } from "react";
import Nav from "./Nav";
import backgroundImage from '../assets/Circuitt.PNG'
import Book from '../assets/Book.png'
import BlackCircuits from "../assets/BlackCircuits.PNG";
import IconGif from '../assets/IconGif.gif'


const MultiStepForm = () => {
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage === 100) {
          clearInterval(interval);
        }

        return prevPercentage + 1;
      });
    }, 10);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const doThing = () => {
      const images = document.getElementsByTagName("img")
      const imageArr = [...images];
      const numImages = imageArr.length;
      document.body.style.backgroundColor = "transparent";

      let numLoaded = 0;
      const onLoad = () => {
        numLoaded++;
        if (numLoaded === numImages) {
          setLoaded(true);
        }
      };

      for (let i = 0; i < numImages; i++) {
        const image = imageArr[i];
        if (image.complete) {
          onLoad();
        } else {
          image.addEventListener("load", onLoad);
        }
      }
    };
    setTimeout(() => {
      doThing();
    }, 1100);

  }, []);
  const [formData, setFormData] = useState({
    //question1: "",
    question1: "",
    question2: "",
    question3: "",
    //question5: "",
    //question6: "",
    //question7: "",
    //question8: "",
  });

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formQuestions = {
    //question1: "I acknowledge the private sale price of a standard codex is 15 SOL or 20 SOL for a custom.",
    question1: "Discord Name (e.g. .Tim#1965)",
    question2: "Twitter Handle (e.g. @CodexDAO)",
    question3: "About Me & My Background In NFTs",
    //question5: "What Are You Looking To Gain From Joining Codex?",
    //question6: "How Can You Help Make Codex A Better Place?",
    //question7: "DYOR: Choose a project to research and let us know what you determine about the project's potential.",
    //question8: "Have you already contacted us via Discord/Twitter?",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formId = "1FAIpQLSee5haeH85vGFdBSepgmEvPugCpbmfglgmlLrUBh4fprN4LnQ";
    const test = new URLSearchParams();
    //test.append("entry.1799498145", formData.question1);
    test.append("entry.2036168126", formData.question1);
    test.append("entry.1430484315", formData.question2);
    test.append("entry.296824948", formData.question3);
    //test.append("entry.984324048", formData.question5);
    //test.append("entry.1947498134", formData.question6);
    //test.append("entry.828473917", formData.question7);
    //test.append("entry.2045355743", formData.question8);

/*
entry.2036168126: ignore please 1
entry.1430484315: ignore please 2
entry.296824948: ignore please 3
entry.984324048: ignore please 4
entry.1947498134: ignore please 5
entry.828473917: ignore please 6
entry.1799498145: Yes
entry.2045355743: Yes
*/

  
    try {
      await fetch(`https://docs.google.com/forms/d/e/${formId}/formResponse`, {
        method: "POST",
        body: test,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        mode: "no-cors",
      });
        
      const webhookUrl = "https://discord.com/api/webhooks/1108174373438902353/4jBEktTG9zrj8E2_wkQO_uMUxwzoFrhTUGe_RmLS3zb3jX3lJXq9DM2EHkKfC-6_9jtM";

      /* Helpers */
      const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

      function transformStringToArray(inputString, chunkSize) {
        if (typeof inputString !== 'string') {
          return ["Input is not a valid string."];
        }
      
        const result = [];
        let currentIndex = 0;
      
        while (currentIndex < inputString.length) {
          const chunk = inputString.substr(currentIndex, chunkSize);
          result.push(chunk);
          currentIndex += chunkSize;
        }
      
        return result;
      }
      
      /* Helpers end */


      //const desc = `**Price acknowledge**: ${formData.question1}\n**Discord**: ${formData.question2}\n**Twitter**: ${formData.question3}\n**About me**: ${formData.question4}\n**Codex gains**: ${formData.question5}\n**Better place**: ${formData.question6}\n**DYOR**: ${formData.question7}\n**Already contacted**: ${formData.question8}`;
      const desc = `**Discord**: ${formData.question1}\n**Twitter**: ${formData.question2}\n**About me**: ${formData.question3}`;
      const descArr = transformStringToArray(desc, 4095);

      for (let i = 0; i < descArr.length; i++) {

        let descSubStr = descArr[i];

        let payload = {
          content: "New form submission:",
          embeds: [
            {
              title: "Form Data",
              description: descSubStr,
            },
          ],
        };
    
        await sleep(300);
        await fetch(webhookUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
    };
        
      console.log("Submitted. Thanks.");
      setIsSubmitted(true); // Set isSubmitted to true after successful submission

      /* Posts a separator */
      let payload = {
        content: "--------------------------------------------------------------------------------",
      };
      try {
        await fetch(webhookUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      } catch(e) { console.log('Posting separator failed. Who cares.'); }
      /* Posts a separator end */

    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
    console.log(formData);
  };

  const handleNextQuestion = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentQuestion((prevIndex) => {
        const nextIndex = prevIndex + 1;
        setIsTransitioning(false);
        return nextIndex <= 4 ? nextIndex : prevIndex;
      });
    }, 500); // Adjust the duration of the transition in milliseconds
  };

  const handlePrevQuestion = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentQuestion((nextIndex) => {
        const prevIndex = nextIndex - 1;
        setIsTransitioning(false);
        return prevIndex >= 1 ? prevIndex : nextIndex;
      });
    }, 500); // Adjust the duration of the transition in milliseconds
  };



  return (

<div className="h-[100%] w-[100%]">


    <div className={`loading-container bg-black h-[100%] w-[100%] ${!loaded ? 'flex' : 'hidden'}`}>
          <img src={IconGif} alt="Loading gif" className="gif" />
          <div
            className="loading-bar"
            style={{ width: `${loadingPercentage}%` }}
          ></div>
        </div>
        <div className={`md:px-4 application-container flex flex-col items-center h-screen bg-cover transition-opacity duration-1000 h-[100%]${
          loaded ? "opacity-100" : "opacity-0"
        }`} style={{ backgroundImage: `url(${BlackCircuits})` }}>
          <Nav currentpage={"application"}/>
          <div className="container mt-16 flex   lg:flex-row flex-col items-center  justify-center test">
            <div className="container flex-col justify-between ">
            <div className="paragraph-container  flex flex-col lg:pr-8">
            <h1 className='heading first-h '>Application</h1>
    
              <p className="paragraph-text regular-text text-justify mt-8">
              The Council is always looking for extraordinary members. Those who believe they can make significant contributions toward our commitment to further Codex are encouraged to submit an application for consideration toward our private auctions. Establishing our community is our strongest priority. As such, public actions information will be shared at a later date and posted on our Twitter. We look forward to meeting you.
              </p>
            </div>
            {isSubmitted ? ( // Check if the form is submitted
              <div className="thank-you-container pt-8">
                <p className="thank-you-text regular-text">Thank you for submitting your application to Codex. We will reach out to you if you are approved.</p>
              </div>
            ) : (
              <div className={`form-container mt-8 flex flex-col  items-center  ${isTransitioning ? 'transitioning' : ''}`}>
              <form onSubmit={handleSubmit} className='flex w-full'>
                <div className="question-container flex flex-col  grow justify-center items-start">
                  <h2 className="heading-small question-title border" >Q: {currentQuestion}/3</h2>
                  {currentQuestion === 1 && (
                      <div><p className="paragraph-text regular-text text-justify mb-4">In order for your application to become valid, please make sure you join our <a href="https://discord.gg/codexdao">Discord</a>.</p></div>
                    )}
                    {currentQuestion === 2 && (
                      <div><p className="paragraph-text regular-text text-justify mb-4">In order for your application to become valid, please make sure you follow our <a href="https://twitter.com/CodexDAO">Twitter</a>.</p></div>
                    )}
                  {currentQuestion !== 99 && currentQuestion !== 99 ? (
                    <textarea
                      type="textarea"
                      name={`question${currentQuestion}`}
                      value={formData[`question${currentQuestion}`]}
                      onChange={handleChange}
                      placeholder={formQuestions[`question${currentQuestion}`]}
                      className="mb-4 textbox"
                    />
                  ) : (
                    <div className="radio-border">
                      <h1 className="regular-text text-white mb-4">{formQuestions[`question${currentQuestion}`]}</h1>
                      <div className="radio-goup">
                        <div className="radio-yes">
                          <input
                            type="radio"
                            id="radio-yes"
                            name={`question${currentQuestion}`}
                            value="Yes"
                            onChange={handleChange}
                            className="form-radio h-4 w-4 text-blue-500"
                          />
                          <label htmlFor="radio-yes" className="ml-2">
                            Yes
                          </label>
                        </div>
                      
                        <div className="radio-no">
                          <input
                            type="radio"
                            id="radio-no"
                            name={`question${currentQuestion}`}
                            value="No"
                            onChange={handleChange}
                            className="form-radio h-4 w-4 text-blue-500"
                          />
                          <label htmlFor="radio-no" className="ml-2">
                            No
                          </label>
                        </div>
                      </div>
    
                    </div>
                  )}
                  <div className="button-container container flex justify-between mb-8">
                    {currentQuestion > 1 && (
                      <button type="button" onClick={handlePrevQuestion} className="learn-more text-white">
                        PREVIOUS
                      </button>
                    )}
                    {currentQuestion < 3 ? (
                      <button type="button" onClick={handleNextQuestion} className="learn-more text-white w-40 p-4">
                        NEXT
                      </button>
                    ) : (
                      <button type="submit" className="learn-more text-white">SUBMIT</button>
                    )}
                  </div>
                </div>
              </form>
            </div>
    
            )}
            </div>
    
              <div className="container hidden lg:block">
              <div className="book-container container mx-auto">
              <img src={Book} alt="" className="book"/>
            </div>
              </div>
           
          </div>
        </div>
</div>




  );
};

export default MultiStepForm;
