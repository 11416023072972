import React from "react";
import "./PrivateSale.css";
import Circuit from "../assets/Circuit2.PNG";
import Blocks from "../assets/blocks5.png";
import collaboration from "../assets/collaboration.svg";
import profit from "../assets/profit.svg";
import knowledge from "../assets/knowledge.svg";
import tie from "../assets/tie.svg";
import handshake from "../assets/handshake.svg";
import Nav from "./Nav";
import IconGif from '../assets/IconGif.gif'
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState, useRef } from "react";

const PrivateSale = () => {
  const [loaded, setLoaded] = useState(false);
  const collapseRefs = useRef([]);


  const handleIconHover = (index) => {

    window.scrollTo({
      top: collapseRefs.current[index].offsetTop,
      behavior: 'smooth'
  })
  
  
  };


  const [isLoading, setIsLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage === 100) {
          clearInterval(interval);
        }

        return prevPercentage + 1;
      });
    }, 10);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const doThing = () => {
      const images = document.getElementsByTagName("img")
      const imageArr = [...images];
      const numImages = imageArr.length;
      document.body.style.backgroundColor = "transparent";

      let numLoaded = 0;
      const onLoad = () => {
        numLoaded++;
        if (numLoaded === numImages) {
          setLoaded(true);
        }
      };

      for (let i = 0; i < numImages; i++) {
        const image = imageArr[i];
        if (image.complete) {
          onLoad();
        } else {
          image.addEventListener("load", onLoad);
        }
      }
    };
    setTimeout(() => {
      doThing();
    }, 1100);

  }, []);




  return (

    <div className="h-[100%] w-[100%]">
    <div className={`loading-container bg-black h-[100%] w-[100%]   ${loaded ? 'hidden' : 'flex'}`}>
              <img src={IconGif} alt="Loading gif" className="gif" />
              <div
                className="loading-bar"
                style={{ width: `${loadingPercentage}%` }}
              ></div>
            </div>
    
            <div className={` md:px-4 items-center justify-start vision-container transition-opacity duration-1000 h-[100%]` } style={{ backgroundImage: `url(${Circuit})` }}>
              <Nav currentpage={"privatesale"}/>
            <div className='container  mx-auto mt-16 flex lg:flex-row flex-col  md:gap-16  gap-8 '>



            <div style={{position:"relative","width":"100%","height":"0","paddingTop":"56.2500%","paddingBottom":"0","boxShadow":"0 2px 8px 0 rgba(63,69,81,0.16)","marginTop":"1.6em","marginBottom":"0.9em","overflow":"hidden","borderRadius":"8px","willChange":"transform"}}>
              <iframe loading="lazy" style={{"position":"absolute","width":"100%","height":"100%","top":"0","left":"0","border":"none","padding":"0","margin":"0"}} src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFs2oeTMvU&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen"></iframe>
            </div>



            </div>
            </div>
    
    
       </div>
    



  );
};

export default PrivateSale;