import React, { useState, useEffect } from "react";
import "../components/Nav.css";
import MeIcon from "../assets/MEEicon.svg";
import logo from "../assets/logo.png";
import TwitterIcon from "../assets/twitter.svg";
import DiscordIcon from "../assets/discord.svg";
import AE from "../assets/artexchange.svg";
import draft from "../assets/draft.jpg";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { useWindowSize } from "@react-hook/window-size";
import { Link } from "react-router-dom";

const Nav = (props) => {
  const [closed, isClosed] = useState(true);
  const [width] = useWindowSize();
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (width >= 768 && !closed) {
      isClosed(true);
    }
  }, [width, closed]);

  const hamburgerShift = () => {
    isClosed(!closed);
  };

  const handleClose = () => {
    isClosed(true);
  };

  return (
    <div
      className={`nav-container ${
        closed ? "container mx-auto pt-[1rem]" : "fixed top-0"
      }`}
    >
      <div className='container-one'>
        <div className='logo-container'>
          {closed ? (
            <Link to='/'>
              <img
                src={logo}
                alt=''
                className={`cursor-pointer ${
                  props.currentpage === "home" ? "" : "page-link"
                }`}
              />
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className='pages-container'>
          <ul>
            <li>
              <Link
                to='/'
                className={`link ${
                  props.currentpage !== "home"
                    ? "page-link"
                    : "current-page border-b-4 border-[#34B9DF]"
                }`}
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                to='/gallery'
                className={`link ${
                  props.currentpage !== "gallery"
                    ? "page-link"
                    : "current-page border-b-4 border-[#34B9DF]"
                }`}
              >
                GALLERY
              </Link>
            </li>
            <li>
              <Link
                to='/vision'
                className={`link ${
                  props.currentpage !== "vision"
                    ? "page-link"
                    : "current-page border-b-4 border-[#34B9DF]"
                }`}
              >
                VISION
              </Link>
            </li>
            {/*<li>
              <Link
                to='/privatesale'
                className={`link ${
                  props.currentpage !== "privatesale"
                    ? "page-link"
                    : "current-page border-b-4 border-[#34B9DF]"
                }`}
              >
                PRIVATE SALE
              </Link>
              </li>*/}
            <li>
              <Link
                to='/application'
                className={`link ${
                  props.currentpage !== "application"
                    ? "page-link"
                    : "current-page border-b-4 border-[#34B9DF]"
                }`}
              >
                APPLICATION
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='container-two'>
        <div className='links-container'>
          <a
            href='https://discord.gg/codexdao'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={DiscordIcon} alt='' className='link' />
          </a>
          <a
            href='https://twitter.com/CodexDAO'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={TwitterIcon} alt='' className='link' />
          </a>
          {/*<a
            href='https://magiceden.io/marketplace/codex'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={MeIcon} alt='' className='link' />
          </a>*/}
          <a
            href='https://exchange.art/codex/nfts'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={AE} alt='' className='link' />
          </a>
        </div>
        <div className='hamburger-container md:hidden'>
          {closed ? (
            <GiHamburgerMenu
              className='hamburger-icon'
              onClick={hamburgerShift}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {closed ? null : (
        <div className='container-three container'>
          <div className='hamburger-close-container container mx-auto px-4 md:px-auto'>
            <AiOutlineClose
              className='hamburger-close-icon'
              onClick={handleClose}
            />
          </div>
          <nav className='hamburger-nav'>
            <ul>
              <li>
                <Link
                  to='/gallery'
                  className={`page-link link ${
                    props.currentpage === "gallery"
                      ? "current-page border-b-4 border-[#34B9DF]"
                      : ""
                  }`}
                >
                  GALLERY
                </Link>
              </li>
              <li>
                <Link
                  to='/vision'
                  className={`page-link link ${
                    props.currentpage === "vision"
                      ? "current-page border-b-4 border-[#34B9DF]"
                      : ""
                  }`}
                >
                  VISION
                </Link>
              </li>
              <li>
                <Link
                  to='/application'
                  className={`page-link link ${
                    props.currentpage === "application"
                      ? "current-page border-b-4 border-[#34B9DF]"
                      : ""
                  }`}
                >
                  APPLICATION
                </Link>
              </li>
            </ul>
          </nav>
          <div className='social-container'>
            <a
              href='https://discord.gg/codexdao'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={DiscordIcon} alt='' className='link' />
            </a>
            <a
              href='https://twitter.com/CodexDAO'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={TwitterIcon} alt='' className='link' />
            </a>
            <a
              href='http://www.magiceden.io'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={MeIcon} alt='' className='link' />
            </a>
            <a
              href='https://exchange.art/codex/on-sale'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={AE} alt='' className='link' />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
