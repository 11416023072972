import React, { useState, useEffect } from "react";
import "./App.css";
import "./assets/BeyondMars.ttf";
import Home from "./components/Home";
import Nav from "./components/Nav";
import Gallery from "./components/Gallery";
import Vision from "./components/Vision";
import PrivateSale from "./components/PrivateSale";
import IconGif from "./assets/IconGif.gif";
import Form from "./components/Application";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {



  return (
    <div className="page-container ">

        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home/>} />
            <Route path="/vision" element={<Vision />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/application" element={<Form />} />
            <Route path="/privatesale" element={<PrivateSale />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
