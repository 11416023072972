import React, { useState, useEffect } from "react";
import "../components/Home.css";
import draft from "../assets/draft.jpg";
import IconGif from "../assets/IconGif.gif";
import { Link } from "react-router-dom";
import Nav from "./Nav";

const Home = () => {
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    const doThing = () => {
      const images = document.getElementsByTagName("img")
      const imageArr = [...images];
      const numImages = imageArr.length;
      document.body.style.backgroundColor = "transparent";

      let numLoaded = 0;
      const onLoad = () => {
        numLoaded++;
        if (numLoaded === numImages) {
          setLoaded(true);
        }
      };

      for (let i = 0; i < numImages; i++) {
        const image = imageArr[i];
        if (image.complete) {
          onLoad();
        } else {
          image.addEventListener("load", onLoad);
        }
      }
    };
    setTimeout(() => {
      // document.addEventListener("DOMContentLoaded", doThing());
      doThing();
    }, 1100);
    // doThing();

    // return () => {
    //   for (let i = 0; i < numImages; i++) {
    //     const image = images[i];
    //     //image.removeEventListener("load", onLoad);
    //   }
    // };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage === 100) {
          clearInterval(interval);
        }

        return prevPercentage + 1;
      });
    }, 10);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="h-[100%] w-full">
      <div
        className={`loading-container bg-black h-[100%] w-[100%] ${
          loaded ? "hidden" : "flex"
        }`}
      >
        <img src={IconGif} alt="Loading gif" className="gif" />
        <div className="loading-bar" style={{ width: `${loadingPercentage}%` }}></div>
      </div>
      <div
        className={`home-container = duration-1000 h-[100%]`}
        style={{ backgroundImage: `url(${draft})` }}
      >
        <Nav currentpage={"home"} />
        <div className="container overlay-text px-4 md:px-auto mx-auto">
          Welcome to <br className="breaks" /> Codex
          <Link to="/vision" className="learn-more mb-8">
            ENTER
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
